.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.addBorder {
  border: 1px solid black;
}

.addBorder-l {
  border-left: 1px solid black;
}

.addBorder-b {
  border-bottom: 1px solid black;
}

.appearance-on {
  appearance: auto;
}

.hiddenScrollBar {
  scrollbar-width: none;
}

.hiddenScrollBar::-webkit-scrollbar {
  width: 0;
}

.t {
  background-image: none;
}

.overflow-gradient {
  background: linear-gradient(rgba(0, 0, 0, 0) 50%, rgb(53, 52, 52) 90%);
}

.elipsis {
  border-radius: 9999px;
  height: 0.4rem;
  width: 0.4rem;
  background-color: rgb(133 133 246 / var(--tw-bg-opacity));
}
