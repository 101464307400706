@import '~@gouvfr/dsfr/dist/dsfr/dsfr.min.css';
@import '~@gouvfr/dsfr/dist/utility/icons/icons-buildings/icons-buildings.min.css';
@import '~@gouvfr/dsfr/dist/utility/icons/icons-system/icons-system.min.css';
@import '~@gouvfr/dsfr/dist/utility/icons/icons-business/icons-business.min.css';
@import '~@gouvfr/dsfr/dist/utility/icons/icons-media/icons-media.min.css';
@import '~@gouvfr/dsfr/dist/utility/icons/icons-document/icons-document.min.css';
@import '~@gouvfr/dsfr/dist/utility/icons/icons-communication/icons-communication.min.css';

@tailwind base;
@tailwind components;
@tailwind utilities;

.rm-link-underline {
  background-image: none;
}

.clamp {
  display: -webkit-box;
  --nb-lines: 3;
  -webkit-line-clamp: var(--nb-lines);
  -webkit-box-orient: vertical;
  overflow: hidden;
  --line-height: 22px;
  line-height: var(--line-height);
  min-height: calc(var(--nb-lines) * var(--line-height));
}

.clamp-2 {
  display: -webkit-box;
  --nb-lines: 1;
  -webkit-line-clamp: var(--nb-lines);
  -webkit-box-orient: vertical;
  overflow: hidden;
  --line-height: 22px;
  line-height: var(--line-height);
  min-height: calc(var(--nb-lines) * var(--line-height));
}

select:focus-visible,
textarea:focus,
input:focus {
  outline: 1px solid #0a76f6;
}

select:focus-visible,
button:focus-visible,
a:focus-visible,
textarea:focus-visible,
input:focus-visible {
  outline: 2px solid #0a76f6;
}

.card-animation {
  --h-before: 181px;
  --h-after: 224px;
  --m-after: 0px;
  --m-before: calc(var(--h-after) / 2 - var(--h-before) / 2 + var(--m-after));

  transition-property: all;
  transition-timing-function: cubic-bezier(0, 0, 0.2, 1);
  transition-duration: 400ms;
  height: var(--h-before);
  margin-top: var(--m-before);
  margin-bottom: var(--m-before);
  --arrow-bottom: 15px;
  --arrow-right: 15px;
}

.card-arrow {
  transition-property: all;
  transition-timing-function: cubic-bezier(0, 0, 0.2, 1);
  transition-duration: 400ms;
}

.card-animation:hover {
  height: var(--h-after);
  margin-top: var(--m-after);
  margin-bottom: var(--m-after);
  --arrow-bottom: calc(15px + var(--m-before));
  --arrow-right: 15px;
}

.fr-pagination__link[aria-current] {
  --hover: inherit;
  --active: inherit;
  background-color: inherit;
  color: inherit;
}

.fr-pagination__link[aria-current='page'] {
  --hover: var(--background-active-blue-france-hover);
  --active: var(--background-active-blue-france-active);
  background-color: var(--background-active-blue-france);
  color: var(--text-inverted-blue-france);
}

.dangerouslySetInnerHTML li {
  margin-left: 23px;
}

h1:focus-visible {
  outline: 2px solid transparent;
}
